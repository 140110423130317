import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route,  Routes} from 'react-router-dom';
import HomePage from './Pages/Home/HomePage';
import ProjectsPage from './Pages/ProjectsPage/Projects';
import App from './App';
import Design from './Pages/Designs/Design';

function Main() {
  return (
    <Router>
    <div className="Main" id="Main">
      <Routes>
        <Route path="/" element={<HomePage />} /> 
        <Route path="/projects" element={<ProjectsPage />} />
        <Route path="/designs" element={< Design/>} />

      </Routes>
    </div>
  </Router>
  );
}

export default Main