import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import DrawSvg from "../components/DrawSvg";
import { motion } from "framer-motion";

const Roadmap = () => {
  // const revealRefs = useRef([]);
  // revealRefs.current = [];

  // gsap.registerPlugin(ScrollTrigger);

  // const addToRefs = (el) => {
  //   if (el && !revealRefs.current.includes(el)) {
  //     revealRefs.current.push(el);
  //   }
  // };

  // useLayoutEffect(() => {
  //   let t1 = gsap.timeline();
  //   revealRefs.current.forEach((el, index) => {
  //     t1.fromTo(
  //       el.childNodes[0],
  //       {
  //         y: "0",
  //       },
  //       {
  //         y: "-30%",
  //         scrollTrigger: {
  //           id: `section-${index + 1}`,
  //           trigger: el,
  //           start: "top center+=200px",
  //           end: "bottom center",
  //           scrub: true,
  //         },
  //       }
  //     );
  //   });

  //   return () => {};
  // }, []);

  return (
    <Section id="roadmap">
<SkillTitleContainer>
          <div>
            <motion.span
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.3, delay: 0.3 }}
            >
              Road
            </motion.span>
          </div>
          <div>
            <motion.span
              initial={{
                x: 70,
                opacity: 0,
                y: 20,
              }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.3, delay: 0.5 }}
            >
              Map
            </motion.span>
          </div>
        </SkillTitleContainer>      <Container>
        <SvgContainer>
          <DrawSvg />
        </SvgContainer>
        <Items>
          <Item>&nbsp;</Item>
          <RoadmapItem
            title="Cosmos Unleashed 🌌"
            subtext="Your brand isn't a logo; it's a celestial entity waiting to shine. Imagine a website that's a constellation of possibilities."
          />
          <RoadmapItem
            title=" Launchpad to Creativity 🌠"
            subtext="Propel your brand into the creative stratosphere. Picture a website that's not just navigated but experienced, a digital odyssey like never before."
          />
          <RoadmapItem
            title="Palette of Imagination 🎨"
            subtext="Let your brand's colors tell a story. Our palette isn't just hues; it's a canvas where imagination and innovation converge."
          />
          <RoadmapItem
            title="Virtual Voyages 🚀"
            subtext="Navigate the digital landscape in ways unexplored. Your website isn't just a URL; it's a portal to immersive experiences that captivate and resonate."
          />
          <RoadmapItem
            title="Beyond Horizons 🌅	"
            subtext="The horizon is just the beginning. Imagine a brand design that extends beyond limits. Your brand, redefined, reaching new horizons of success."
          />
        </Items>
      </Container>
    </Section>
  );
};

//Single Roadmap Item
const RoadmapItem = ({ title, subtext, addToRef }) => {
  return (
    <Item >
      <ItemContainer>
        <Box>
          <SubTitle>{title}</SubTitle>
          <Text>{subtext}</Text>
        </Box>
      </ItemContainer>
    </Item>
  );
};

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  background-color: #000;
  position: relative;
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  font-weight: 500;
  color: ${(props) => props.theme.text};
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 1rem auto; */
  padding-top:1rem;

  border-bottom: 2px solid ${(props) => props.theme.text};
  width: fit-content;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const Container = styled.div`
  width: 70%;
  height: 200vh;
  background-color: #000;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 64em) {
    width: 80%;
  }

  @media (max-width: 48em) {
    width: 90%;
  }
`;

const SvgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SkillTitleContainer = styled.div`
  width: calc(400px + 5vw);
  color: white;
  margin-left: calc(5rem + 40vw);
  color: rgba(255, 255, 255, 0.9);
  font-family: "Ubuntu", sans-serif;
  font-size: calc(4rem + 2vw);
  @media screen and (min-width: 2000px) {
    margin-left: calc(10rem + 3vw);
    margin-top: calc(4rem + 3vw);
  }
  @media screen and (max-width: 850px) {
    font-size: calc(4rem + 3vw);
  }
  @media screen and (max-width: 480px) {
    font-size: calc(4rem + 2vw);
    margin-left: 2.5rem;
  }
  div {
    overflow: hidden;
  }
  span:nth-child(1) {
    display: block;
  }
  span:nth-child(2) {
    margin-left: 10rem;
  }
`;
const Items = styled.ul`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 48em) {
    width: 90%;
  }

  & > *:nth-of-type(2n + 1) {
    justify-content: start;

    @media (max-width: 48em) {
      justify-content: center;
    }

    div {
      border-radius: 50px 0 50px 0;
      text-align: right;

      @media (max-width: 48em) {
        border-radius: 0 50px 0 50px;
        text-align: left;

        p {
          border-radius: 0 40px 0 40px;
        }
      }
    }
    p {
      border-radius: 40px 0 40px 0;
    }
  }

  & > *:nth-of-type(2n) {
    justify-content: end;

    @media (max-width: 48em) {
      justify-content: center;
    }

    div {
      border-radius: 0 50px 0 50px;
      text-align: left;
    }

    p {
      border-radius: 0 40px 0 40px;
    }
  }
`;

const Item = styled.li`
  width: 100%;
  height: 100%;
  display: flex;

  @media (max-width: 48em) {
    justify-content: flex-end !important;
  }
`;

const ItemContainer = styled.div`
  width: 40%;
  height: fit-content;
  padding: 1rem;
  border: 6px solid ${(props) => props.theme.text};

  @media (max-width: 48em) {
    width: 70%;
  }
`;

const Box = styled.p`
  height: fit-content;
  background-color: ${(props) => props.theme.carouselColor};
  color: ${(props) => props.theme.text};
  padding: 1rem;
  position: relative;
  border: 1px solid ${(props) => props.theme.text};
`;

const SubTitle = styled.span`
  display: block;
  text-align:left;

  font-size: ${(props) => props.theme.fontxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontlg};
  }
`;

const Text = styled.span`
  display: block;
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0.5rem 0rem;
  text-transform: capitalize;
  text-align:left;
  color: black;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxs};
  }
`;
const FixLine = styled.div`
  width: 100vw;
  height: 3rem;
  z-index:-3px;
  background-position:bottom;
  background-size:contain;
  background-color: white;
  position: absolute;
  background-repeat:repeat-x;
  top: -2rem;
`;
export default Roadmap;
