import styled from "styled-components";

const Button = ({ text, link, onClick }) => {
  if (link) {
    return (
      <a href={link} aria-label={text} target="_blank" rel="noreferrer">
        <Btn>{text}</Btn>
      </a>
    );
  } else {
    // If no link is provided, render the button with an onClick handler
    return <Btn onClick={onClick}>{text}</Btn>;
  }
};

const Btn = styled.button`
  display: inline-block;
  background-color: #f5d08c;
  color: ${(props) => props.theme.body};
  outline: none;
  border: none;
  font-size: 2.875rem;
  padding: 0.9rem 2.3rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;

  &:hover {
    transform: scale(0.9);
  }

  &::after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    border: 2px solid #202020;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    transition: all 0.2s ease;
  }

  &:hover::after {
    transform: translate(-50%, -40%) scale(1);
    padding: 0.3rem;
  }
`;

export default Button;