import styled from "styled-components";

import img1 from "../assets/graphic.png";
import img2 from "../assets/avatar2.png";
import img3 from "../assets/ceo.png";
import img4 from "../assets/mhd.png";
import img5 from "../assets/cbo.png";

import { motion } from "framer-motion";

const Team = () => {
  return (
    <Section id="team">
      <SkillTitleContainer>
        <div>
          <motion.span
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.3, delay: 0.3 }}
          >
            Our
          </motion.span>
        </div>
        <div>
          <motion.span
            initial={{
              x: 70,
              opacity: 0,
              y: 20,
            }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.3, delay: 0.5 }}
          >
            Team
          </motion.span>
        </div>
      </SkillTitleContainer>
      <Container>

      <MemberComponent
          img={img5}
          name="Eng.Saif Hijazi"
          position="Chairman of the board"
        />
      <MemberComponent
          img={img3}
          name="Eng.Safwan Madkhaneh"
          position="Chief executive officer"
        />
      
       
        <MemberComponent
          img={img4}
          name="Eng.Mohammad Aloqlah"
          position="IT Director"
        />
        <MemberComponent
          img={img1}
          name="Eng.Sami Eskandarany"
          position="Art Director"
        />
        <MemberComponent
          img={img2}
          name="Eng.Sedra Mhanna"
          position="Full stack developer"
        />
      </Container>
    </Section>
  );
};

const MemberComponent = ({ img, name = " ", position = " " }) => {
  return (
    <Item>
      <ImgContainer>
        <img src={img} loading="lazy" alt={name} />
      </ImgContainer>
      <Name>{name}</Name>
      <Position>{position}</Position>
    </Item>
  );
};

const Section = styled.section`
  min-height: 70vh;
  width: 100vw;
  background-color: #000;
  position: relative;
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  font-weight: 500;
  color: ${(props) => props.theme.text};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-bottom: 2px solid ${(props) => props.theme.text};
  width: fit-content;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const Container = styled.div`
  width: 75%;
  margin: 0rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 64em) {
    width: 80%;
  }

  @media (max-width: 48em) {
    width: 90%;
    justify-content: center;
  }
`;

const Item = styled.div`
  width: calc(30rem - 7vw);
  height:35vh;
  padding: 1rem 0;
  background-color: ${(props) => props.theme.carouselColor};
  margin: 2rem 1rem;
  margin-bottom:1rem;
  margin-left:0.5rem;
  position: relative;
  border: 2px solid ${(props) => props.theme.text};
  border-radius: 20px;
  backdrop-filter: blur(4px);
  z-index: 5;

  /* &:hover {
    img {
      transform: translateY(-1rem) scale(1.2);
    }
  } */

  @media (max-width: 30em) {
    width: 70vw;
  }
`;

const ImgContainer = styled.div`
  width: 90%;
  height: 76%;
  margin: 0 auto;
  background-color: #000;
  border: 1px solid ${(props) => props.theme.text};
  border-radius: 20px;


  img {
    width: 100%;
    height: 100%;
  }
`;
const SkillTitleContainer = styled.div`
  width: calc(400px + 5vw);
  color: #000;
  margin-left: calc(5rem + 1vw);
  /* color: rgba(255, 255, 255, 0.9); */
  font-family: "Ubuntu", sans-serif;
  font-size: calc(4rem + 2vw);
  @media screen and (min-width: 2000px) {
    margin-left: calc(10rem + 3vw);
    margin-top: calc(4rem + 3vw);
  }
  @media screen and (max-width: 850px) {
    font-size: calc(4rem + 3vw);
  }
  @media screen and (max-width: 480px) {
    font-size: calc(4rem + 2vw);
    margin-left: 2.5rem;
  }
  div {
    overflow: hidden;
  }
  span:nth-child(1) {
    display: block;
  }
  span:nth-child(2) {
    margin-left: 10rem;
  }
`;

const Name = styled.h2`
  font-size: ${(props) => props.theme.fontlg};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  /* color: ${(props) => props.theme.text}; */
  color:#000;
  margin-top: 0.1rem;
`;

const Position = styled.h2`
  font-size: ${(props) => props.theme.fontmd};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  /* color: ${(props) => `rgba(${props.theme.textRgba}, 0.9)`}; */
  color:#000;
  font-weight: 400;
`;


export default Team;
