import React, { useEffect, useRef, useState } from "react";
import App from "../../App";
import GlobalStyles from "../../styles/GlobalStyles";
import { ThemeProvider } from "styled-components";
import { theme } from "../../styles/Themes";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import Preloader from "../../components/Preloader/Preloader";
import ScrollTriggerProxy from "../../components/ScrollTriggerProxy";
import { AnimatePresence } from "framer-motion";
import { useLocation } from "react-router-dom";

  function HomePage() {
    // const location = useLocation();
   
    // useEffect(() => {
    //   window.location.reload();
    // }, [location])
  const containerRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 4200);
  }, []);
  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <LocomotiveScrollProvider
          options={{
            smooth: true,
            // ... all available Locomotive Scroll instance options
            smartphone: {
              smooth: true,
            },
            tablet: {
              smooth: true,
            },
          }}
          watch={
            [
              //..all the dependencies you want to watch to update the scroll.
              //  Basicaly, you would want to watch page/location changes
              //  For exemple, on Next.js you would want to watch properties like `router.asPath` (you may want to add more criterias if the instance should be update on locations with query parameters)
            ]
          }
          containerRef={containerRef}
        >
          {loaded ? null : <Preloader />}
          <ScrollTriggerProxy />
          <AnimatePresence>
            <main
              style={{ overflowX: "hidden", overflowY: "hidden" }}
              className="App"
              data-scroll-container
              ref={containerRef}
            >
              <App />
            </main>
          </AnimatePresence>
        </LocomotiveScrollProvider>
      </ThemeProvider>
    </>
  );
}

export default HomePage;
