import React, { useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Navbar from "../../components/Navbar/Navbar";

const images = [
  "https://mir-s3-cdn-cf.behance.net/project_modules/fs/e1d2e8187642299.658d3e0763885.jpg",
  "https://mir-s3-cdn-cf.behance.net/project_modules/fs/d04da2187642299.658d2f8e177ac.jpg",
  "https://mir-cdn.behance.net/v1/rendition/project_modules/1400_opt_1/60bb52187642299.658d457621172.png",
  "https://mir-s3-cdn-cf.behance.net/project_modules/fs/9f9a2b176561003.6586bbaa1e169.jpg",
  "https://mir-s3-cdn-cf.behance.net/project_modules/fs/e5f088176561003.6586bbaa1bfbb.jpg",
  "https://mir-cdn.behance.net/v1/rendition/project_modules/1400_opt_1/6823c4187360271.6586b89e222cc.jpg",
  "https://mir-cdn.behance.net/v1/rendition/project_modules/1400_opt_1/bc7713187360271.6586b89e232db.jpg",
  "https://mir-s3-cdn-cf.behance.net/project_modules/fs/77ded3187363491.6586c81b2b7fd.jpg",
  "https://mir-s3-cdn-cf.behance.net/project_modules/fs/953218187642299.658d45762203c.jpg",
  "https://mir-s3-cdn-cf.behance.net/project_modules/fs/86c260187363491.6586c62f24304.jpg",
  "https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/271eb6187363491.6586c62f262c8.jpg",
  "https://mir-s3-cdn-cf.behance.net/project_modules/fs/297072187363491.6586c81b2a9c8.jpg",
  "https://mir-cdn.behance.net/v1/rendition/project_modules/1400_opt_1/bc7713187360271.6586b89e232db.jpg",

  "https://mir-s3-cdn-cf.behance.net/project_modules/fs/845c83187498893.658a9d1b4f133.jpg",
  "https://mir-s3-cdn-cf.behance.net/project_modules/fs/31b67c187363491.6587fceb64b55.jpg",
  "https://mir-s3-cdn-cf.behance.net/project_modules/fs/56bd0b187363491.6586c62f25415.jpg",
  "https://mir-s3-cdn-cf.behance.net/project_modules/fs/d66f15187360271.6586b89e2047d.jpg",
  "https://mir-s3-cdn-cf.behance.net/project_modules/fs/c40d59176561003.6586bbaa1928d.jpg",
  "https://mir-s3-cdn-cf.behance.net/project_modules/fs/fed7cd187498893.658a8d850ed2b.jpg",
  "https://mir-s3-cdn-cf.behance.net/project_modules/fs/c17734187498893.658a8d850e094.jpg",
];
const Design = () => {
  const [data, setData] = useState({ img: "", i: 0 });

  const viewImage = (img, i) => {
    console.log({img,i});
  };

  const imgAction = (action) => {
    let i = data.i;
    if (action === "next-img") {
      setData({ img: images[i + 1], i: i + 1 });
    }
    if (action === "prev-img") {
      setData({ img: images[i - 1], i: i - 1 });
    }
    if (!action) {
      setData({ img: "", i: 0 });
    }
  };
  return (
    <>
      {data.img && (
        <div
          style={{
            width: "100%",
            height: "100vh",
            background: "black",
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <button
            // onClick={() => imgAction()}
            style={{ position: "absolute", top: "10px", right: "10px" }}
          >
            X
          </button>
          <button onClick={() => imgAction("prev-img")}>Previous</button>

          <img
            src={data.img}
            style={{ width: "auto", maxWidth: "90%", maxHeight: "90%" }}
            alt=" "
          />
          <button onClick={() => imgAction("next-img")}>Next</button>
        </div>
      )}
      <div style={{ background: "black" }}>
        {" "}
        <Navbar />
        <div style={{ padding: "10px", background: "black" }}>
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
          >
            <Masonry gutter="20px">
              {images.map((image, i) => (
                <img
                  key={i}
                  src={image}
                  style={{ width: "100%", display: "block"}}
                  alt=""
                  onClick={() => viewImage(image, i)}
                />
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </div>
      </div>
    </>
  );
};

export default Design;
