// Themes
export const theme = {
  body: "#202020", // black shade
  text: "#fff",
  bodyRgba: "32,32,32",
  textRgba: "255,255,255",
  carouselColor: "#f0b64a",

  grey: "#bebebe", // grey shade

  fontxs: "0.75rem", //1em = 16px
  fontsm: "0.875rem",
  fontmd: "1rem",
  fontlg: "1.25rem",
  fontxl: "2rem",
  fontxxl: "3rem",
  fontxxxl: "5rem",
  fontBig: "10rem",
  squareLenghtXsm: "40px",
  squareLenghtSm: "70px",
  squareLenghtLg: "200px",
  squareLenghtXl: "300px",
  circleDiameter: "500px",
  navHeight: "8rem",
};

export const light = {
  body: "#202020",
  text: "#fff",
  bodyRgba: "32,32,32",
  textRgba: "255, 255, 255",

  carouselColor: "#EEEDDE",

  fontxs: "0.75rem",
  fontsm: "0.875rem",
  fontmd: "1rem", // 1rrem = 16px
  fontlg: "1.25rem",
  fontxl: "2rem",
  fontxxl: "3rem",
  fontxxxl: "4rem",

  fontButton: "0.875rem",

  navHeight: "5rem",
};