import React from "react";
import styled, { ThemeProvider } from "styled-components";
import Pricing from "../components/PricingComponent/Pricing";
import Button from "../components/Button";
import { motion } from "framer-motion";
import { useRef } from "react";
import { light } from "../styles/Themes";

const PricingContainer = () => {
    const sectionRef = useRef(null);

    return (
    <Section id="pricing">
        <SkillTitleContainer>
            <div>
              <motion.span
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.3, delay: 0.3 }}
              >
                SocialMedia
              </motion.span>
            </div>
            <div>
              <motion.span
                initial={{
                  x: 70,
                  opacity: 0,
                  y: 20,
                }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.3, delay: 0.5 }}
              >
                Pricing
              </motion.span>
            </div>
          </SkillTitleContainer> 
          {/* <ButtonContainer>
            <ThemeProvider theme={light}>
              <Button text="View more" link="#" />
            </ThemeProvider>
          </ButtonContainer> */}
        <MainContainer>
          <div className="pricing-component">
            <Pricing
              data={[
                { text: "Management of 2 Distinctive Social Media Profiles", value: true },
                { text: " Creation of 8 Captivating Content Pieces (8 Posts) ", value: true },
                { text: " Execution of 2 Strategic Advertising Campaigns (valued at $80)", value: true },
                { text: " Monthly In-Depth Performance Reports for Your Accounts", value: true },
                { text: "Dedicated Concierge Admin for Your Accounts", value: false },
                { text: "Privileged Discounts on Additional Bespoke Services", value: false },

              ]}
              price={200}
              duration="m"
              background="linear-gradient(120deg, #DC8E41 0%, #AF6114 100%)"
              shadow="#AF6114"
              currency="$"
              buttonContent="Discover Our Excellence"
              subTitle="Ideal for Emerging Enterprises"
              priceText="Embark on a journey of corporate success with our innovative solutions."
              headerText="Bronze Bouquet"
              buttonColor="#AF6114"
            />
          </div>
          <div className="pricing-component">
            <Pricing
                  data={[
                    { text: "Management of 3 Distinctive Social Media Profiles", value: true },
                    { text: " Creation of 12 Captivating Content Pieces (8 Posts) ", value: true },
                    { text: " Execution of 3 Strategic Advertising Campaigns (valued at $120)", value: true },
                    { text: " Monthly In-Depth Performance Reports for Your Accounts", value: true },
                    { text: "Dedicated Concierge Admin for Your Accounts", value: true },
                    { text: "Privileged Discounts on Additional Bespoke Services", value: false },
    
                  ]}
              price={350}
              duration="m"
              background="linear-gradient(120deg, #D7D7D8 0%, #B4B5B8 100%)"
              shadow="#D7D7D8"
              currency="$"
              buttonContent="Unlock Your Creativity"
              subTitle="Perfect for Aspiring Creators"
              priceText="Embark on the journey of crafting your initial projects with flair."
              headerText="Silver Bouquet"
              buttonColor="#B4B5B8 "

            />
          </div>
          <div className="pricing-component">
            <Pricing
                    data={[
                      { text: "Management of 4 Distinctive Social Media Profiles", value: true },
                      { text: " Creation of 20 Captivating Content Pieces (12 Posts + 8 story) ", value: true },
                      { text: " Execution of 4 Strategic Advertising Campaigns (valued at $160)", value: true },
                      { text: " Monthly In-Depth Performance Reports for Your Accounts", value: true },
                      { text: "Dedicated Concierge Admin for Your Accounts", value: true },
                      { text: "10% Discounts on Additional IT Services", value: true },

                    ]}
              price={500}
              duration="m"
              background="linear-gradient(to left, #FCC201 0%,   #C69320  100%);"
              shadow="#C69320 "
              currency="$"
              buttonContent="Ignite Business Radiance"
              subTitle="Tailored for Visionary Enterprises"
              priceText="Propel your corporate essence to unparalleled brilliance."
              headerText="Golden Bouquet"
              buttonColor="#C69320"

            />
          </div>
          <div className="pricing-component">
            <Pricing
             data={[
              { text: "Management of 3 Distinctive Social Media Profiles", value: true },
              { text: " Creation of 12 Captivating Content Pieces (12 Posts + 8 story) ", value: true },
              { text: " Execution of 4 Strategic Advertising Campaigns (valued at $160)", value: true },
              { text: " Monthly In-Depth Performance Reports for Your Accounts", value: true },
              { text: "Dedicated Concierge Admin for Your Accounts", value: true },
              { text: "20% Discounts on Additional IT Services", value: true },

            ]}
              price={700}
              duration="m"
              background="linear-gradient(to left, #AAE3F0  0%, #9BD4E1 100%);"
              shadow="#B9F2FF"
              currency="$"
              buttonContent="Uncover Radiance"
              subTitle="Tailored Brilliance for Visionary Enterprises"
              priceText="Embark on a journey of radiate brilliance across your brand."
              headerText="Diamond Bouquet"
              buttonColor="#9BD4E1"

            />
          </div>
        </MainContainer>
              
           </Section>
      );
    }
    
    const MainContainer = styled.div`
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      background-color: #000;
      margin-top:1rem;

      @media screen and (max-width: 970px) {
        height: 100%;
        flex-direction: column;
        .pricing-component {
          margin: 2rem 0;
        }
      }
    `;
    const SkillTitleContainer = styled.div`
    width: calc(400px + 5vw);
    color: white;
    margin-left: calc(5rem + 5vw);

    color: rgba(255, 255, 255, 0.9);
    font-family: "Ubuntu", sans-serif;
    font-size: calc(4rem + 2vw);
    @media screen and (min-width: 2000px) {
      margin-left: calc(5rem + 3vw);
      margin-top: calc(4rem + 2vw);
    }
    @media screen and (max-width: 850px) {
      font-size: calc(4rem + 3vw);
    }
    @media screen and (max-width: 480px) {
      font-size: calc(4rem + 2vw);
      margin-left: 2.5rem;
    }
    div {
      overflow: hidden;
    }
    span:nth-child(1) {
      display: block;
    }
    span:nth-child(2) {
      margin-left: 10rem;
    }
  `;
  const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  background-color: #000;
  position: relative;
  
`;
const Number = styled.span`
  color: #AF6114; // Change this to the color you want
`;
const ButtonContainer = styled.div`
  width: 80%;
  display: flex;
  align-self: center;
  margin: 1rem auto 0 auto;

  @media (max-width: 64em) {
    width: 100%;

    button {
      margin: 0 auto;
    }
  }
`;



export default PricingContainer;
