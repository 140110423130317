import taskemanImg from "../assets/Images/projects/Taskeman/brand.jpg";
import clogblogImg from "../assets/Images/projects/Clogblog Mockup/backend.png";
import glowtodoImg from "../assets/Images/projects/Glow Todo/mobile.png";
import ArRahmanImg from "../assets/Images/projects/Ar-rahman/social.png";
import buyHereImg from "../assets/Images/projects/Buy Here/Frontend.png"

const ProjectsData = [
  {
    title: "Brand",
    builtWith:
      "Elevate Your Essence: We Curate Brands, Weave Legacies, and Redefine Identities for Companies Ready to Shine.",
    gitLink: "",
    projectDemoLink: "",
    imageLink: taskemanImg,
  },
  {
    title: "UI/Ux design",
    builtWith:
    "Where Design Meets Function: Crafting Intuitive User Experiences and Striking Visual Interfaces Tailored for Your Company's Success.",
    projectDemoLink: "",
    imageLink: buyHereImg,
  },
  {
    title: "Web development",
    builtWith:
    "Digital Presence Redefined: We create captivating websites with exquisite design and seamless functionality, redefining your digital narrative.",
    gitLink: "",
    projectDemoLink: "",
    imageLink: clogblogImg,
  },
 
  {
    title: "Social Media",
    builtWith:"Transforming brands into digital sensations:Elevate your brand with our expert social media magic – where creativity meets strategy for online success!",
    gitLink: "",
    projectDemoLink: "",
    imageLink: ArRahmanImg,
  },
  {
    title: "Mobile apps",
    builtWith:  "Unlock bespoke mobile solutions for your enterprise, transforming ideas into fluid apps and elevating mobile excellence for unparalleled success.",
    gitLink: "",
    projectDemoLink: "",
    imageLink: glowtodoImg,
  },

];

export default ProjectsData;
