import firstProject from "../../assets/Projects/1.png"
import secondProject from "../../assets/Projects/4.png"
import thirdProject from "../../assets/Projects/3.png"
import fourthProject from "../../assets/Projects/5.png"


const ProjectList = [
    {
      projectImage:firstProject,
      projectImageName: "Broker",
      projectNumber: "01",
      projectHeading: "Broker",
      projectDescription:
      "This is a fully responsive landing page of a resort built with pure html and css. Images used here, are from unsplash.com",
      bgColor: "red",
    },
    {
      projectImage:secondProject,
      projectImageName: "Mayas Geneva",
      projectNumber: "02",
      projectHeading: "Mayas Geneva",
      projectDescription:
      "This is a fully responsive landing page of a resort built with pure html and css. Images used here, are from unsplash.com",
      bgColor: "green",
    },
  
    {
      projectImage: thirdProject,
      projectImageName: "Sigma Orient",
      projectNumber: "03",
      projectHeading: "Sigma Orient",
      projectDescription:
      "This is a fully responsive landing page of a resort built with pure html and css. Images used here, are from unsplash.com",
      bgColor: "yellow",
    },
    {
      projectImage:  fourthProject,
      projectImageName: "Sama Ezdan",
      projectNumber: "04",
      projectHeading: "Sama Ezdan",
      projectDescription:
        "This is a fully responsive landing page of a resort built with pure html and css. Images used here, are from unsplash.com",
      bgColor: "purple",
    },
  ];
  export default ProjectList;
  