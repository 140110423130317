import styled from "styled-components";
import React, { useState, useEffect, useRef } from "react";
import "./Pricing.css";
import { motion } from "framer-motion";

const Pricing = () => {
  const cardsContainerRef = useRef(null);
  const cardsContainerInnerRef = useRef(null);
  const overlayRef = useRef(null);
  const cardsRef = useRef([]);
  const [cards, setCards] = useState([]);
  const sectionRef = useRef(null);
  const isFirstRender = useRef(true);

  useEffect(() => {
    const cardElements = document.querySelectorAll(".card");
    if (cardElements.length > 0) {
      setCards(Array.from(cardElements));
    }
  }, [/* dependencies */]);
  

  useEffect(() => {
    if (!cards.length) return;


    const applyOverlayMask = (e) => {
      const overlayEl = e.currentTarget;
      const x = e.pageX - (cardsContainerRef.current ? cardsContainerRef.current.offsetLeft : 0);
      const y = e.pageY - (cardsContainerRef.current ? cardsContainerRef.current.offsetTop : 0);

      overlayEl.style = `--opacity: 1; --x: ${x}px; --y:${y}px;`;
    };

    const initOverlayCard = (cardEl) => {
      const overlayCard = document.createElement("div");
      overlayCard.classList.add("card");
      overlayRef.current.append(overlayCard);
      observer.observe(cardEl);
      createOverlayCta(overlayCard, cardEl.lastElementChild)
    };

    const createOverlayCta = (overlayCard, ctaEl) => {
      const overlayCta  = document.createElement("div");
      overlayCta.classList.add("cta");
      overlayCta.textContent = ctaEl.textContent;
      overlayCta.setAttribute("aria-hidden", true);
      overlayCard.append(overlayCta);
    };

    const observer  =  new ResizeObserver((entries) => {
      entries.forEach((entry) => {
          const cardIndex  = cards.indexOf(entry.target);
          let width =  entry.borderBoxSize[0].inlineSize;
          let height =  entry.borderBoxSize[0].blockSize;

          if(cardIndex >= 0 ){
              overlayRef.current.children[cardIndex].style.width = `${width}px`;
              overlayRef.current.children[cardIndex].style.height = `${height}px`;
          }
      });
    });
    
    cards.forEach(initOverlayCard);
   // Attach the event listener to the specific section
  sectionRef.current.addEventListener("pointermove", applyOverlayMask);

  return () => {
    observer.disconnect();
    
    // Remove the event listener from the specific section
    sectionRef.current.removeEventListener("pointermove", applyOverlayMask);
  };
  }, [cards]);
  return (
    
    <div  className="container"ref={sectionRef} >
      <SkillTitleContainer>
          <div>
            <motion.span
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.3, delay: 0.3 }}
            >
              Our
            </motion.span>
          </div>
          <div>
            <motion.span
              initial={{
                x: 70,
                opacity: 0,
                y: 20,
              }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.3, delay: 0.5 }}
            >
              Pricing
            </motion.span>
          </div>
        </SkillTitleContainer> 
      <div className="main_flow" >
        <div className="cards main__cards">
          <div className="cards__inner" ref={cardsContainerInnerRef}>
            <div className="cards__card card">
              <h2 className="card__heading">Basic</h2>
              <p className="card__price">$9.99</p>
              <ul role="list" className="card__bullets flow">
                <li>
                  &#x2713; Access to standard workouts and nutrition plans
                </li>
                <li>&#x2713; Email support</li>
                <li>&#x2713; Social media plans</li>
              </ul>
              <a href="#basic" className="card__cta cta">
                Get started
              </a>
            </div>
            <div className="cards__card card">
              <h2 className="card__heading">Pro</h2>
              <p className="card__price">$19.99</p>
              <ul role="list" className="card__bullets flow">
                <li>
                  {" "}
                  &#x2713; Access to advanced workouts and nutrition plans
                </li>
                <li>&#x2713; Priority Email support</li>
                <li>&#x2713; Exclusive access to live access Q&A sessions</li>
              </ul>
              <a href="#pro" className="card__cta cta">
                Upgrade to pro
              </a>
            </div>

            <div className="cards__card card">
              <h2 className="card__heading">Ultimate</h2>
              <p className="card__price">$29.99</p>
              <ul role="list" className="card__bullets flow">
                <li>
                  &#x2713; Access to all premium workouts and nutrition plans
                </li>
                <li> &#x2713; 24/7 priority support</li>
                <li> &#x2713; 1-on-1 virtual coaching session</li>
                <li>
                  &#x2713; Exclusive Content and early access to new features
                </li>
              </ul>
              <a href="#basic" className="card__cta cta">
                Go Ultimate
              </a>
            </div>
            <div className="cards__card card">
              <h2 className="card__heading">Ultimate</h2>
              <p className="card__price">$29.99</p>
              <ul role="list" className="card__bullets flow">
                <li>
                  &#x2713; Access to all premium workouts and nutrition plans
                </li>
                <li> &#x2713; 24/7 priority support</li>
                <li> &#x2713; 1-on-1 virtual coaching session</li>
                <li>
                  &#x2713; Exclusive Content and early access to new features
                </li>
              </ul>
              <a href="#basic" className="card__cta cta">
                Go Ultimate
              </a>
            </div>
          </div>

          <div className="overlay cards__inner" ref={overlayRef}> </div>
        </div>
      </div>
    </div>
  );
};
const SkillTitleContainer = styled.div`
  width: calc(400px + 5vw);
  color: white;
  margin-left: calc(5rem + 10vw);
  color: rgba(255, 255, 255, 0.9);
  font-family: "Ubuntu", sans-serif;
  font-size: calc(4rem + 2vw);
  @media screen and (min-width: 2000px) {
    margin-left: calc(5rem + 3vw);
    margin-top: calc(4rem + 3vw);
  }
  @media screen and (max-width: 850px) {
    font-size: calc(4rem + 3vw);
  }
  @media screen and (max-width: 480px) {
    font-size: calc(4rem + 2vw);
    margin-left: 2.5rem;
  }
  div {
    overflow: hidden;
  }
  span:nth-child(1) {
    display: block;
  }
  span:nth-child(2) {
    margin-left: 10rem;
  }
`;
export default Pricing;
