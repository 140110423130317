import React, { useLayoutEffect, useRef } from "react";
import {
  Section,
  Container,
  ImageContainer,
  AboutTextContainer,
  Overlay,
  FixLine,
  BorderLine,
  LeftLine
} from "../components/AboutStyles/AboutStyles";
import { motion } from "framer-motion";
import gsap from "gsap";
import orpheus from "../assets/orpheus.png";

const About = () => {
  // gsap.registerPlugin(ScrollTrigger);

  const ref = useRef(null);
 

  return (
    <Section ref={ref} id="about">

      <AboutTextContainer>
        <motion.h1
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          Our Concepts
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7, ease: "easeOut", delay: 0.4 }}
        >
        In Heart of Syria, where history meets creativity, and where the language of art meets the rhythm of technology. 
        It is here that they discovered a stunning mosaic painting that tells the story of the legendary musician Orpheus, 
        one of the great figures of the Greeks, who was able to subdue nature, humans, and stone with his magnificent music. 
        {/* "Tanaghom" was born. It is not just a company, but an artistic journey that starts from heritage and reaches the peak of technology and design, a story that celebrates the harmony of ideas and the coherence of colors. */}

        </motion.p>

        <br />
        <motion.p
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: "easeOut", delay: 0.8 }}
        >
          We set sail towards the future, where design and technology intersect in ways that make innovation inhabit every part of our work. 
          Thus, we leave our mark at the heart of progress. We aspire to provide a unique technological solution that maintains a sustainable balance between authenticity and innovation.
         The Tanaghom team believes that harmony arises from mutual cooperation and respect. 
         {/* Each member of the team is an integral part of this painting, where creativity and expertise intertwine to create unparalleled harmony. 
         Like the pieces of mosaic in the painting Orpheus, the team intertwines in Harmony to innovate a unique and beautiful experience. */}
        </motion.p>
      </AboutTextContainer>

      <Overlay />
      <Container>
         <ImageContainer src={orpheus} loading="lazy" alt="orpheus" />
      

      </Container>



    </Section>
  );
};

export default About;
