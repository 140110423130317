import React, { useState } from "react";
import {
  FooterContainer,
  TextContainerWrapper,
  LetsWorkTextContainer,
  SocialIconsContainer,
  FooterTextsContainer,
} from "../components/FooterStyles/FooterStyles";
import { motion } from "framer-motion";
import { SiGmail, SiMessenger, SiWhatsapp } from "react-icons/si";
import {  BsInstagram, BsLinkedin } from "react-icons/bs";
import { MdLocationPin } from "react-icons/md";

function Footer() {
  const [show, setShow] = useState(false);
  return (
    <FooterContainer>
      <TextContainerWrapper>
        <LetsWorkTextContainer>
          <div>
            <motion.p
              initial={{ y: 50, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.2, ease: "easeOut" }}
            >
              Let's Work 
            </motion.p>
          </div>
          <br />
          <div>
            <motion.p
              initial={{ y: 50, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.2, ease: "easeOut" }}
            >
            Together
            </motion.p>
          </div>
        </LetsWorkTextContainer>
        <SocialIconsContainer>
          <span>build your dream... 🌍</span>
          <div>
            <a
              href=""
              rel="noreferrer"
              target="_blank"
              title="Damascus, Syria"
            >
              <MdLocationPin />
            </a>
            <a
              href=""
              rel="noreferrer"
              target="_blank"
            >
              <BsLinkedin />
            </a>
            <a
              href="mailto:info@tanaghomtech.com"
              rel="noreferrer"
              target="_blank"
            >
              <SiGmail title="" />
            </a>
            <a
              href=""
              rel="noreferrer"
              target="_blank"
            >
              <SiWhatsapp title="" />
            </a>
            <a
              href="https://www.facebook.com/Tanaghomtech"
              rel="noreferrer"
              target="_blank"
            >
              <SiMessenger title="" />
            </a>
            <a
              href="https://www.instagram.com/tanaghomtech/"
              rel="noreferrer"
              target="_blank"
            >
              <BsInstagram title="" />
              </a>
             
          </div>
        </SocialIconsContainer>
      </TextContainerWrapper>
      <FooterTextsContainer>
      
        <p>&copy; Tanaghom 2024</p>
        <p>
          Designed and developed by Tanaghom Team. 
        </p>
      </FooterTextsContainer>
    </FooterContainer>
  );
}

export default Footer;
