import React from "react";
import styled, { ThemeProvider } from "styled-components";
import Carousel from "../components/Carousel/Carousel";
import Button from "../components/Button";
import { light, theme } from "../styles/Themes";
import { motion } from "framer-motion";
import { useState } from "react";
import Modal from "../components/Modal/Modal";

const Story = () => {
  const [modal, setModal] = useState(false);
  const Toggle = () => setModal(!modal);
  const closeModal = () => {
    setModal(false); // Assuming setIsModalOpen is the state setter for isModalOpen
  };
  return (
    <Section id="about">
      <Container>
        <Box>
          <Carousel />
        </Box>
        <Box>
          <Title>Tanaghom Tale</Title>
          <SubText>
            Embark on a transformative journey with our IT and design services
            company—a vision born four years ago, realized by dedicated founders
            just a year ago. Inspired by the rich history of Syria, our venture
            is grounded in the enduring values of timeless wisdom. Over these
            few years, we've evolved into a dynamic force, skillfully blending
            modern innovation with the echoes of the past. Our narrative is one
            defined by passion, continuous growth, and an unwavering commitment
            to excellence.
          </SubText>
          <SubTextLight>
            When you choose our services, you're not merely engaging a provider;
            you're becoming part of a story deeply rooted in history, driven by
            the ambition to revolutionize your business. Allow us to craft your
            success story, seamlessly weaving together the ancient and the
            contemporary, where innovation harmonizes with tradition. Opt for
            our services and let us guide your business to unparalleled heights,
            as we collectively shape a narrative that transcends time.
          </SubTextLight>
          <ButtonContainer>
            <ThemeProvider theme={light}>
              <Button text="Contact us" link="#" />
              <Button text="Order now" onClick={Toggle} />
                <Modal show={modal} close={closeModal}  />
            </ThemeProvider>
          </ButtonContainer>
        </Box>
      </Container>
    </Section>
  );
};

const Section = styled.div`
  min-height: 100vh;
  width: 100vw;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
`;

const Container = styled.div`
  width: 75%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 70em) {
    width: 85%;
  }

  @media (max-width: 64em) {
    width: 100%;
    flex-direction: column;

    & > *:last-child {
      width: 80%;
    }
  }

  @media (max-width: 64em) {
    & > *:last-child {
      width: 90%;
    }
  }
`;

const Box = styled.div`
  width: 50%;
  height: 100%;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 40em) {
    min-height: 50vh;
  }
`;

const Title = styled.h2`
  font-size: 5rem;
  text-transform: capitalize;
  color: #f0b64a;
  align-self: flex-start;
  width: 80%;
  margin: 0 auto;
  font-weight: 400;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
  }

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
    margin-top: 1px;
  }

  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontlg};
    margin-top: 1px;
  }
`;

const SubText = styled.p`
  font-size: 1.9rem;
  text-transform: capitalize;
  color: #f5d08c;
  align-self: flex-start;
  margin: 1rem auto;
  width: 80%;
  font-weight: 400;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
    font-size: ${(props) => props.theme.fontmd};
  }

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontmd};
  }

  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
  }
`;

const SubTextLight = styled.p`
  font-size: 1.9rem;
  text-transform: capitalize;
  color: ${(props) => `#f5d08c`};
  align-self: flex-start;
  width: 80%;
  margin: 1rem auto;
  font-weight: 400;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
    font-size: ${(props) => props.theme.fontsm};
  }

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontsm};
  }

  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontxs};
  }
`;

const ButtonContainer = styled.div`
  width: 80%;
  display: flex;
  align-self: flex-start;
  justify-content: space-between;
  margin: 1rem auto;

  @media (max-width: 64em) {
    width: 100%;

    button {
      margin: 0 auto;
    }
  }
`;
const FixLine = styled.div`
  width: 100vw;
  height: 3rem;
  z-index: -3px;
  background-position: bottom;
  background-size: contain;
  background-color: white;
  position: absolute;
  background-repeat: repeat-x;
  top: -0.1rem;
`;

const SkillTitleContainer = styled.div`
  width: calc(400px + 5vw);
  color: white;
  margin-left: calc(5rem + 1vw);
  color: rgba(255, 255, 255, 0.9);
  font-family: "Ubuntu", sans-serif;
  font-size: calc(4rem + 2vw);
  @media screen and (min-width: 2000px) {
    margin-left: calc(2rem + 3vw);
    margin-top: calc(1rem + 3vw);
  }
  @media screen and (max-width: 850px) {
    font-size: calc(4rem + 3vw);
  }
  @media screen and (max-width: 480px) {
    font-size: calc(4rem + 2vw);
    margin-left: 2.5rem;
  }
  div {
    overflow: hidden;
  }
  span:nth-child(1) {
    display: block;
  }
  span:nth-child(2) {
    margin-left: 10rem;
  }
`;
export default Story;
