import React, { useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import PreLoaderImage from "../../assets/logo-img.png"
const PreloaderWrapper = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  @media screen and (max-width: 749px) {
    flex-direction: column;
  }
  div {
    width: 33.33%;
    height: 100%;
    background-color:#f0b64a;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 749px) {
      width: 100%;
      height: 33.33%;
    }
    &:last-child {
      width: 33.34%;
      @media screen and (max-width: 749px) {
        width: 100%;
        height: 33.34%;
      }
    }
    &:nth-child(2) {
      flex-direction: column;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 1rem;
        height: 100%;
        background-color: #000;
        left: -5px;
        top: 0;
        @media screen and (max-width: 749px) {
          width: 100%;
          height: 1rem;
          left: 0;
          top: -5px;
        }
      }
      &::before {
        content: "";
        position: absolute;
        width: 1rem;
        height: 100%;
        background-color: #000;
        right: -5px;
        top: 0;
        @media screen and (max-width: 749px) {
          width: 100%;
          height: 1rem;
          right: 0;
          top: calc(100% - 5px);
        }
      }
      @media screen and (max-width: 1050px) {
        svg {
          width: 30vw;
          height: 30vw;
        }
      }
      p {
        margin-right: 4rem;
        font-size: calc(2rem + 2vw);
        font-family: "Raleway", sans-serif;
        @media screen and (max-width: 1050px) {
          margin-right: 0rem;
        }
        @media screen and (max-width: 450px) {
          font-size: calc(2rem + 1vw);
        }
        @media screen and (max-width: 380px) {
          font-size: calc(1rem + 1vw);
        }
        span {
          color: red;
        }
      }
    }
  }
`;

function Preloader() {
  useEffect(() => {
    let targets = gsap.utils.toArray(".preloader-sections");
    ScrollTrigger.matchMedia({
      "(min-width: 750px)": function () {
        gsap.to(targets, {
          height: "0%",
          duration: 1,
          stagger: 0.3,
          delay: 3,
          ease: "Power3.easeOut",
        });
      },
      "(max-width: 749px)": function () {
        gsap.to(targets, {
          width: "0%",
          duration: 1,
          stagger: 0.3,
          delay: 3,
          ease: "Power3.easeOut",
        });
      },
    });
  }, []);
  return (
    <PreloaderWrapper>
      <div className="preloader-sections"></div>
      <div className="preloader-sections">
        <motion.svg
          initial={{ opacity: 1 }}
          animate={{ opacity: 0 }}
          transition={{ delay: 3 }}
          width="400"
          height="400"
          viewBox="0 0 400 400"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_103_2)">
       
         
          </g>
          <defs>
            <clipPath id="clip0_103_2">
              <rect width="400" height="400" fill="white" />
            </clipPath>
          </defs>
        </motion.svg>
        <motion.p
          initial={{ opacity: 1 }}
          animate={{ opacity: 0 }}
          transition={{ delay: 3 }}
        >
          <span>Ta</span>naghom
        </motion.p>
      </div>
      <div className="preloader-sections">
      </div>
    </PreloaderWrapper>
  );
}

export default Preloader;
