import styled, { css } from "styled-components";
import Close from "../../assets/times-solid.svg";
import background from "../../assets/logo-img.png";
import { useRef, useState } from "react";
import { useEffect } from "react";
import axios from "axios";

const Modal = ({ show, close }) => {
  const [service_id, setServices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get(
          "https://www.tanaghomtech.com/portal/public/api/services"
        );
        console.log(response.data.data);
        setServices(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchServices();
  }, []);
  console.log("Loading");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    // Create an object with the form data
    const formData = {
      fullName: event.target.name.value,
      companyName: event.target.company.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      service_id: event.target.service_id.value,
      budget: event.target.budget.value,
      orderDescription: event.target.message.value,
    };
    try {
      // Send the form data to your backend
      console.log(formData);
      const response = await axios.post(
        "https://www.tanaghomtech.com/portal/public/api/orders",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": csrfToken,
          },
        }
      );
      alert("Your information has been sent successfully!");
      console.log(response.data.data); // Handle the response from the server
    } catch (error) {
      alert("There was an error sending your information.");
      console.error("Error:", error); // Handle the error
    }
  };
  const ContactForm = () => (
    <div>
      <h3>Email Us</h3>
      <Form onSubmit={handleSubmit}>
        <WrapperGrid>
          <Label onClick={(event) => event.stopPropagation()}>Name</Label>
          <Input type="text" name="name" />
        </WrapperGrid>
        <WrapperGrid>
          <Label onClick={(event) => event.stopPropagation()}>Company</Label>
          <Input type="text" name="company" />
        </WrapperGrid>
        <WrapperGrid>
          <Label onClick={(event) => event.stopPropagation()}>
            Email Address
          </Label>
          <Input type="email" name="email" />
        </WrapperGrid>
        <WrapperGrid>
          <Label onClick={(event) => event.stopPropagation()}>
            Phone Number
          </Label>
          <Input type="text" name="phone" />
        </WrapperGrid>
        <WrapperGrid>
          <Label onClick={(event) => event.stopPropagation()}>Services</Label>
          <Select name="service_id">
            {service_id &&
              service_id.length > 0 &&
              service_id.map((service) => (
                <option key={service.id} value={service.id}>
                  {service.ServiceName}
                </option>
              ))}
          </Select>
        </WrapperGrid>
        <WrapperGrid>
          <Label onClick={(event) => event.stopPropagation()}>Budget</Label>
          <Select name="budget">
            <option value="100$-1000$">100$-1000$</option>
            <option value="1000$-10000$">1000$-10000$</option>
            <option value="10000$-100000$">10000$-100000$</option>
            <option value="more than 100000$">more than 100000$</option>
          </Select>
        </WrapperGrid>
        <WrapperGrid full>
          <Label onClick={(event) => event.stopPropagation()}>Message</Label>
          <Textarea name="message" rows="5"></Textarea>
        </WrapperGrid>
        <WrapperGrid full>
          <StyledButton>Submit</StyledButton>
        </WrapperGrid>
      </Form>
    </div>
  );

  const CompanyInfo = () => (
    <WrapperCompanyInfo>
      <CompanyName>Tanaghom for IT and Design services</CompanyName>
      <WrapperList>
        <li>Damascus, Al hijaz</li>
        <li>+963 951 985 074</li>
        <li>info@tanaghomtech.com</li>
      </WrapperList>
    </WrapperCompanyInfo>
  );
  return (
    <>
      {show ? (
        <>
          <Backdrop onClick={close} />
          <Container onClick={(event) => event.stopPropagation()}>
            <CloseButton onClick={close} />
            {/* <Title text="Title" /> */}
            <Container wrapper>
              <CompanyInfo />
              <ContactForm />
            </Container>
          </Container>
        </>
      ) : null}
    </>
  );
};

const StyledButton = styled.button`
  background: #f0b64a;
  border: 0;
  color: #fff;
  padding: 1em;
  text-transform: uppercase;
  width: 100%;
  cursor: pointer;
  &:hover,
  &:focus {
    background: #eaca8e;
    color: #fff;
    outline: 0;
    transition: background-color 2s ease-out;
  }
`;

const Container = styled.div`
  background-color: white;
  width: 90%;
  position: fixed;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;

  ${(props) =>
    props.wrapper &&
    css`
      box-shadow: 0 0 20px 0 rgba(187, 51, 17, 0.7);
      > * {
        padding: 1em;
      }

      @media (min-width: 700px) {
        display: grid;
        top: 51%;
        left: 50%;
        position: fixed; /* Fixed position to stay in place */
        transform: translate(-50%, -50%); /* Center the modal */

        grid-template-columns: 1fr 2fr;
        > * {
          padding: 2em;
        }
      }
    `}
  @media (max-width: 700px) {
    display: grid;
    top: 37%;
    left: 50%;
    position: fixed;
    transform: translate(-50%, -50%); /* Center the modal */
  }
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr); /* Two columns on larger screens */
  }
`;

const Input = styled.input`
  border: 1px solid #f0b64a;
  padding: 1em;
  width: 100%;
`;

const Select = styled.select`
  border: 1px solid #f0b64a;
  padding: 1em;
  width: 100%;
`;
const Label = styled.label`
  display: block;
  &:hover {
    cursor: pointer;
  }
`;

const Textarea = styled.textarea`
  border: 1px solid #f0b64a;
  padding: 1em;
  width: 100%;
`;

const Title = ({ className, text }) => <h1 className={className}>{text}</h1>;

const StyledTitle = styled(Title)`
  color: #f0b64a;
  font: 70px;
  text-align: center;
  @media (min-width: 700px) {
    text-align: left;
  }
`;
const WrapperGrid = styled.div`
  ${(props) =>
    props.full &&
    css`
      grid-column: 1 / 3;
    `}
`;

const WrapperCompanyInfo = styled.div`
  background: #f0b64a;
`;

const CompanyName = styled.h3`
  margin: 0 0 1rem 0;
  text-align: center;
  font-size: 1.7rem;
  color: #fff;
  @media (min-width: 700px) {
    text-align: left;
  }
`;

const WrapperList = styled.ul`
  list-style: none;
  margin: 0 0 1rem 0;
  padding: 0;
  font-size: 1.5rem;
  text-align: center;
  @media (min-width: 700px) {
    text-align: left;
  }
`;
const Backdrop = styled.div`
  position: fixed; /* Position it fixed so it's always in view */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 10;
`;

const CloseButton = styled.button`
  position: absolute; // Position it at the top-right corner of the modal
  top: -230px;
  right: 64px; // Adjust spacing from the right
  background: url(${Close}) no-repeat;
  border: none; // Remove the border
  width: 24px; // Size of the close button
  height: 24px; // Size of the close button
  cursor: pointer; // Change the cursor
  z-index: 30;
`;

export default Modal;
